import { Button, ButtonProps } from '@mui/material';
import CircularLoader from '../CircularLoader';

type InputButtonType = {
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  danger?: boolean;
  success?: boolean;
  size?: string;
} & ButtonProps;
const InputButton = ({
  handleClick,
  children,
  type = 'submit',
  loading,
  disabled,
  danger = false,
  success = false,
  size = 'large',
  sx,
  ...rest
}: InputButtonType) => {
  const buttonDisabled = loading || disabled;

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      disableRipple
      disabled={buttonDisabled}
      type={type}
      sx={{
        margin: '10px 0',
        textTransform: 'capitalize',
        color: '#fff',
        backgroundColor: danger ? '#E00000' : success ? '#F56329' : '#F56329',

        '&:hover': {
          backgroundColor: danger ? '#8F1423' : success ? '#EA4A0B' : '#EA4A0B'
        },
        ...sx
      }}
      size={size}
      {...rest}
    >
      {loading ? <CircularLoader /> : children}
    </Button>
  );
};

export default InputButton;
