const config = {
  token: '@RAL-Token',
  userData: '@RAL-User',
  issuerData: '@RAL-Issuer',
  roles: '@RAL-Roles',
  merchantID: '@RAL-MerchantID',
  loginEndpoint: '/v1/admin/login',
  registerEndpoint: '/v1/admin/create/staff',
  retrievePasswordOneEndpoint: '/v1/admin/retrieve/password/1',
  retrievePasswordTwoEndpoint: '/v1/admin/retrieve/password/2',
  updatePasswordEndpoint: '/v1/admin/update/password',
  onboardIssuerEndpoint: '/v1/admin/create/staff',
  verifyTfaEndpoint: '/v1/admin/verify-tfa'
  // getProfileEndpoint: ''
  // other auth endpoints should come in here
};

export default config;
