import { Box, Checkbox, Typography, styled } from '@mui/material';
import React from 'react';

type LoginActionProp = {
  children: React.ReactNode;
};

const Aside = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const LoginActions = ({ children }: LoginActionProp) => {
  const label = { inputProps: { 'aria-label': 'Remember me' } };

  return (
    <div>
      <Aside component={'aside'}>
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            margin: '10px 0'
          }}
        >
          <Checkbox
            {...label}
            defaultChecked
            disableRipple
            sx={{
              padding: '0',
              color: '#F56329',
              '&.Mui-checked': {
                color: '#F56329'
              }
            }}
          />
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Remember me
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <a
              href="/forgot-password"
              style={{
                // fontWeight: '600',
                color: '#000',
                marginLeft: '7px',
                textDecoration: 'underline'
              }}
            >
              Forgot Password?
            </a>
          </Typography>
        </Box>
      </Aside>
      <Box sx={{ marginY: '5px' }}>{children}</Box>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        Don't have an account?{' '}
        <a
          href="/register"
          style={{
            color: '#000',
            // fontWeight: '600',
            textDecoration: 'underline'
          }}
        >
          Get started
        </a>
      </Typography>
    </div>
  );
};

export default LoginActions;
