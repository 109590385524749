const stagingUrl = process.env.REACT_APP_STAGING_URL;
const productionUrl = process.env.REACT_APP_PRODUCTION_URL;
const interstateStagingUrl = process.env.REACT_APP_INTERSTATE_STAGING_URL;
const InterstateProductionUrl = process.env.REACT_APP_INTERSTATE_PRODUCTION_URL;
const cowryAppStagingUrl = process.env.REACT_APP_COWRY_APP_STAGING_URL;
const cowryAppProductionUrl = process.env.REACT_APP_COWRY_APP_PRODUCTION_URL;

export const APP_BASE_URL = process.env.NODE_ENV !== 'production' ? stagingUrl : productionUrl;
export const APP_INTERSTATE_BASE_URL =
  process.env.NODE_ENV !== 'production' ? interstateStagingUrl : InterstateProductionUrl;
export const APP_COWRY_BASE_URL =
  process.env.NODE_ENV !== 'production' ? cowryAppStagingUrl : cowryAppProductionUrl;
