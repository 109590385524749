import { useState, useCallback } from 'react';
import toast from 'react-hot-toast';

export type UseClipboardResult = {
  copy: (text: string) => void;
  copied?: boolean;
};

const useClipboard = (resetDuration: number = 2000): UseClipboardResult => {
  const [copied, setCopied] = useState(false);

  const copy = useCallback(
    (text: string) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopied(true);
          toast.success('Copied to clipboard');
          setTimeout(() => setCopied(false), resetDuration);
        })
        .catch((err) => {
          toast.error('Failed to copy to clipboard');
          console.error('Failed to copy text: ', err);
        });
    },
    [resetDuration]
  );

  return { copy, copied };
};

export default useClipboard;
