import { Theme } from '@mui/material/styles';

const GlobalStyles = (theme: Theme) => {
  return {
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
      {
        '-webkit-box-shadow': `0 0 0 30px ${theme.palette.mode === 'light' ? '#FFF !important' : '#121212'} inset !important`, // Change 'white' to your desired background color
        '-webkit-text-fill-color': `${theme.palette.mode === 'light' ? '#000 !important' : '#FFF'} !important` // Change '#000' to your desired text color
      },
    'input:autofill, input:autofill:hover, input:autofill:focus, input:autofill:active': {
      'box-shadow': `0 0 0 30px ${theme.palette.mode === 'light' ? '#FFF !important' : '#121212'} inset !important`, // Change 'white' to your desired background color
      '-moz-box-shadow': `0 0 0 30px ${theme.palette.mode === 'light' ? '#FFF !important' : '#121212'} inset !important`, // Change 'white' to your desired background color
      '-moz-text-fill-color': `${theme.palette.mode === 'light' ? '#000 !important' : '#FFF'} !important` // Change '#000' to your desired text color
    }
  };
};

export default GlobalStyles;
