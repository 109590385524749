import IconsWrapper from './IconsWrapper';

export const CancelIcon = () => {
  return (
    <IconsWrapper>
      <svg
        width="64px"
        height="64px"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="3"
        stroke="currentColor"
        fill="none"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <line x1="8.06" y1="8.06" x2="55.41" y2="55.94"></line>
          <line x1="55.94" y1="8.06" x2="8.59" y2="55.94"></line>
        </g>
      </svg>
    </IconsWrapper>
  );
};
