import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@/hooks';
import { ThemeContext } from '@/theme/ThemeProvider';
import ThemeIconButton from '@/assets/appBar/Icons/ThemeIconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SuperMenuBar from './SuperMenuBar';
import { Box, Typography, IconButton } from '@mui/material';
import AppBarUi from './AppBarUi';
import { styled } from '@mui/system';

const BoxBar = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>(
  ({ theme, open }) => ({
    ...(open && {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    })
  })
);

type SuperAppBarProp = {
  open: boolean;
  drawerWidth: number;
  handleDrawerToggle: () => void;
};

export default function SuperAppBar({ open, drawerWidth, handleDrawerToggle }: SuperAppBarProp) {
  const { handleSetTheme, mode } = useContext(ThemeContext);
  const location = useLocation();
  const { user, issuer } = useAuth();

  const pathName = location.pathname.includes('-')
    ? location.pathname.split('-').join(' ').slice(1)
    : location.pathname.slice(1);

  return (
    <AppBarUi open={open} drawerWidth={drawerWidth}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <IconButton aria-label="open drawer" onClick={handleDrawerToggle} edge="start">
          <MenuIcon />
        </IconButton>

        <Box>
          <Typography
            variant="h6"
            color="primary"
            noWrap
            sx={{
              fontWeight: '600',
              textTransform: 'capitalize',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              alignItems: 'center',
              gap: 1
            }}
          >
            {(function () {
              if (pathName !== 'app') {
                return pathName.split('/')[0];
              }
              return `${issuer?.issuerName}`;
            })()}
          </Typography>
          {pathName === 'app' && <Typography color="primary">{`(${user?.fullname})`}</Typography>}
        </Box>
      </Box>
      <BoxBar open={open}>
        <IconButton color="inherit" aria-label="open drawer" onClick={handleSetTheme} edge="start">
          <ThemeIconButton mode={mode} />
        </IconButton>
        <SuperMenuBar />
      </BoxBar>
    </AppBarUi>
  );
}
