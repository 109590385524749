import { useState, useEffect } from 'react';

type UseDebounce = (value: string, delay: number, updatable?: boolean, length?: number) => string;

const useDebounce: UseDebounce = (value, delay, updatable = false, length = 11) => {
  const [debouncedValue, setDebouncedValue] = useState<string>(value);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    clearTimeout(timeoutId as NodeJS.Timeout);

    if (updatable && (value === '' || value.length > length)) {
      setDebouncedValue(value);
    } else {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      setTimeoutId(timer);
    }

    return () => {
      clearTimeout(timeoutId as NodeJS.Timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay, updatable]);

  return debouncedValue;
};

export { useDebounce };
