import { ActivityIcon } from './ActivityIcon';
import { AdministrationIcon } from './AdministrationIcon';
import { ArrowLeftIcon } from './ArrowLeftIcon';
import { ArrowRightIcon } from './ArrowRightIcon';
import { BillsIcon } from './BillsIcon';
import { BoxIcon } from './BoxIcon';
import { BusinessesIcon } from './BusinessIcon';
import { CashierIcon } from './CashierIcon';
import { CategoriesIcon } from './CategoriesIcon';
import { DashboardIcon } from './DashboardIcon';
import { DeductionIcon } from './DeductionIcon';
import { DevicesIcon } from './DevicesIcon';
import { DownloadIcon } from './DownloadIcon';
import { KycIcon } from './KycIcon';
import { LogoutIcon } from './LogoutIcon';
import { PersonIcon } from './PersonIcon';
import { PlusIcon } from './PlusIcon';
import { SearchIcon } from './SearchIcon';
import { SettingsIcon } from './SettingsIcon';
import { SubagentsIcon } from './SubagentsIcon';
import { TopupIcon } from './TopupIcon';
import { TransactionsIcon } from './TransactionIcon';
import { TransferIcon } from './TransferIcon';
import { WithdrawalIcon } from './WithdrawalIcon';
import { CancelIcon } from './CancelIcon';
import { PosIcon } from './PosIcon';
import { PlaygroundIcon } from './PlaygroundIcon';
import { DeleteIcon } from './DeleteIcon';
import { ProductIcon } from './ProductIcon';
import { AccessIcon } from './AccessIcon';
import { GiftCardIcon } from './GiftCardIcon';
import { InterstateIcon } from './InterstatIcon';
import { LockIcon } from './LockSetting';
import { MailIcon } from './MailIcon';
import { ThemeIcon } from './ThemeIcon';
import { CalenderIcon } from './CalenderIIcon';
import { NameIcon } from './NameIcon';
import { LocationIcon } from './LocationIcon';
import UpdateIcon from './UpdateIcon';
import { ViewIcon } from './ViewIcon';
import { EmptyIcon } from './EmptyIcon';

type IconSelectType = {
  name:
    | 'dashboard'
    | 'giftcard'
    | 'interstate'
    | 'admin'
    | 'transactions'
    | 'activity'
    | 'settings'
    | 'business'
    | 'cashier'
    | 'mail'
    | 'deduction'
    | 'person'
    | 'logout'
    | 'subagent'
    | 'kyc'
    | 'withdrawal'
    | 'devices'
    | 'theme'
    | 'bills'
    | 'calender'
    | 'topup'
    | 'transfer'
    | 'search'
    | 'download'
    | 'plus'
    | 'categories'
    | 'box'
    | 'arrow-right'
    | 'arrow-left'
    | 'cancel'
    | 'product'
    | 'delete'
    | 'agent'
    | 'access'
    | 'lock'
    | 'location'
    | 'default'
    | string;
  color?: string;
};
const IconSelect = ({ name, color = '#fff' }: IconSelectType) => {
  switch (name) {
    case 'dashboard':
      return <DashboardIcon />;
    case 'admin':
      return <AdministrationIcon />;
    case 'transactions':
      return <TransactionsIcon />;
    case 'calender':
      return <CalenderIcon />;
    case 'update':
      return <UpdateIcon />;
    case 'agent':
      return <NameIcon />;
    case 'location':
      return <LocationIcon />;
    case 'activity':
      return <ActivityIcon />;
    case 'settings':
      return <SettingsIcon />;
    case 'business':
      return <BusinessesIcon />;
    case 'cashier':
      return <CashierIcon />;
    case 'deduction':
      return <DeductionIcon />;
    case 'person':
      return <PersonIcon />;
    case 'logout':
      return <LogoutIcon />;
    case 'view':
      return <ViewIcon />;
    case 'eye':
      return <ViewIcon />;
    case 'subagent':
      return <SubagentsIcon />;
    case 'kyc':
      return <KycIcon />;
    case 'withdrawal':
      return <WithdrawalIcon />;
    case 'devices':
      return <DevicesIcon />;
    case 'bills':
      return <BillsIcon />;
    case 'topup':
      return <TopupIcon />;
    case 'transfer':
      return <TransferIcon />;
    case 'search':
      return <SearchIcon />;
    case 'download':
      return <DownloadIcon />;
    case 'box':
      return <BoxIcon />;
    case 'delete':
      return <DeleteIcon />;
    case 'mail':
      return <MailIcon />;
    case 'product':
      return <ProductIcon />;
    case 'access':
      return <AccessIcon />;
    case 'theme':
      return <ThemeIcon />;
    case 'lock':
      return <LockIcon />;
    case 'plus':
      return <PlusIcon color={color} />;
    case 'arrow-right':
      return <ArrowRightIcon />;
    case 'arrow-left':
      return <ArrowLeftIcon />;
    case 'categories':
      return <CategoriesIcon />;
    case 'playground':
      return <PlaygroundIcon />;
    case 'pos':
      return <PosIcon />;
    case 'cancel':
      return <CancelIcon />;
    case 'interstate':
      return <InterstateIcon />;
    case 'giftcard':
      return <GiftCardIcon />;
    case '':
      return <EmptyIcon />;
    default:
      return <DashboardIcon />;
  }
};

export default IconSelect;
