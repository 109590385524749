const saveToSStorage = (key: string, data: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error({ error });
  }
};

const getFromSStorage = (key: string) => {
  try {
    const storageData = sessionStorage.getItem(key);
    if (storageData) {
      return JSON.parse(storageData);
    }
  } catch (error) {
    console.log({ error });
  }
};

export { saveToSStorage, getFromSStorage };
