import { createContext, useEffect, useState, ReactNode } from 'react';
import toast from 'react-hot-toast';

import axios, { formatRequestPayload } from '@/api';
import { AxiosResponse } from 'axios';
import authConfig from '@/config/auth';
import { ResponsePayload } from '@/api/types';
import {
  ForgotPasswordPayload,
  Issuer,
  LoginPayload,
  LoginResponsePayload,
  RegisterPayload,
  ResetPasswordPayload,
  UpdatePasswordPayload,
  User,
  OnboardIssuerRequestPayload,
  UserType
} from '@/api/auth/types';
import { getFromSStorage, saveToSStorage } from '@/libs/storage';
import { VerifyTFARequestPayload } from '@/api/tfa/types';
export type SuccessCallbackType = (res: AxiosResponse) => void;

export type ErrCallbackType = (err: any) => void;

export type AuthValuesType = {
  loading: boolean;
  user: User | null;
  issuer: Issuer | null;
  roles: string[] | null;
  login: (
    payload: LoginPayload,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => Promise<void>;
  onboardIssuer: (
    payload: OnboardIssuerRequestPayload & { __token: string },
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => Promise<void>;
  register: (
    payload: RegisterPayload,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => Promise<void>;
  forgotPassword: (
    payload: ForgotPasswordPayload,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => Promise<void>;
  resetPassword: (
    payload: ResetPasswordPayload,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => Promise<void>;
  updatePassword: (
    payload: UpdatePasswordPayload,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => Promise<void>;
  loginViaTfaVerification: (
    payload: VerifyTFARequestPayload,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => Promise<void>;
  logout: (url?: string) => void;
};

const defaultProvider: AuthValuesType = {
  user: null,
  issuer: null,
  roles: null,
  loading: true,
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  onboardIssuer: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  loginViaTfaVerification: () => Promise.resolve()
};

const AuthContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | null>(defaultProvider.user);
  const [issuer, setIssuer] = useState<Issuer | null>(defaultProvider.issuer);
  const [roles, setRoles] = useState<string[] | null>(defaultProvider.roles);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);

  const initAuth = () => {
    setLoading(true);
    try {
      const token = getFromSStorage(authConfig.token);
      if (token) {
        const user = getFromSStorage(authConfig.userData);
        const issuer = getFromSStorage(authConfig.issuerData);
        const roles = getFromSStorage(authConfig.roles);

        if (user) setUser(user);
        if (issuer) setIssuer(issuer);
        if (roles) {
          const privileges = roles?.map((role: any) => role.service);
          setRoles(privileges);
        }
      } else {
        handleLogout();
      }
    } catch {
      handleLogout();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initAuth();
  }, []);

  const setAuthorization = (loginDetails: LoginResponsePayload, type = 'login') => {
    const { token, data, issuerProfile, merchantID, roles } = loginDetails;
    saveToSStorage(authConfig.token, token);
    if (type === 'login') {
      saveToSStorage(authConfig.userData, data[0]);
      saveToSStorage(authConfig.merchantID, merchantID);
      saveToSStorage(authConfig.issuerData, issuerProfile[0]);
      saveToSStorage(authConfig.roles, roles);
      setUser(data[0]);
      setIssuer(issuerProfile[0]);
    }
  };

  const userHasAuthorization = (data: LoginResponsePayload): boolean => {
    return (
      data?.usertype !== UserType.ISSUER_GUEST &&
      typeof data?.isTFARequired === 'boolean' &&
      !data?.isTFARequired
    );
  };

  const handleLoginViaTfaVerification: AuthValuesType['loginViaTfaVerification'] = async (
    payload,
    successCallback,
    errorCallback
  ) => {
    try {
      const response = await axios.post<ResponsePayload<LoginResponsePayload>>(
        authConfig.verifyTfaEndpoint,
        formatRequestPayload(payload)
      );

      const { data } = response;

      if (data?.error?.status === 1) {
        toast.error(data?.error?.message);
      }

      if (userHasAuthorization(data.content)) {
        // user has created their profile, they can access the dashboard.
        setAuthorization(data.content);
        initAuth();
      }

      if (successCallback) successCallback(response);
    } catch (err: any) {
      toast.error(err?.data?.error?.message || 'Login failed');
      if (errorCallback) errorCallback(err);
    }
  };

  const handleLogin: AuthValuesType['login'] = async (payload, successCallback, errorCallback) => {
    try {
      const response = await axios.post<ResponsePayload<LoginResponsePayload>>(
        authConfig.loginEndpoint,
        formatRequestPayload(payload)
      );
      const { data } = response;

      if (data.error.status === 1) {
        toast.error(data?.error?.message);
      }

      if (userHasAuthorization(data.content)) {
        // user has created their profile, they can access the dashboard.
        setAuthorization(data.content);
        initAuth();
      }

      if (successCallback) successCallback(response);
    } catch (err: any) {
      toast.error(err?.data?.error?.message || 'Login failed');
      if (errorCallback) errorCallback(err);
    }
  };

  const handleOnboardIssuer: AuthValuesType['onboardIssuer'] = async (
    payload,
    successCallback,
    errorCallback
  ) => {
    try {
      const response = await axios.post(
        authConfig.onboardIssuerEndpoint,
        formatRequestPayload(payload)
      );

      if (response?.data?.error?.status === 1) {
        toast.error(response?.data?.error?.message);
        return;
      }

      if (successCallback) successCallback(response);
    } catch (res: any) {
      toast.error(res?.data?.error?.message || 'Onboarding Failed');
      if (errorCallback) errorCallback(res);
    }
  };

  const handleRegister: AuthValuesType['register'] = async (
    payload,
    successCallback,
    errorCallback
  ) => {
    try {
      const response = await axios.post(authConfig.registerEndpoint, formatRequestPayload(payload));
      if (successCallback) successCallback(response);
    } catch (err: any) {
      toast.error(err?.data?.error?.message || 'Registration failed');
      if (errorCallback) errorCallback(err);
    }
  };

  const handleRetrievePassword = async (
    payload: ForgotPasswordPayload | ResetPasswordPayload,
    endpoint: string,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => {
    try {
      const response = await axios.post(endpoint, formatRequestPayload(payload));
      if (successCallback) successCallback(response);
    } catch (err: any) {
      toast.error(err?.data?.error?.message || 'Failed!');
      if (errorCallback) errorCallback(err);
    }
  };

  const handleUpdatePassword: AuthValuesType['updatePassword'] = async (
    payload: UpdatePasswordPayload,
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => {
    try {
      const response = await axios.put<ResponsePayload>(
        authConfig.updatePasswordEndpoint,
        formatRequestPayload(payload)
      );

      toast.success(response?.data?.success?.message);
      if (successCallback) successCallback(response);
      handleLogout();
    } catch (err: any) {
      toast.error(err?.data?.error?.message || 'Password update failed');
      if (errorCallback) errorCallback(err);
    }
  };

  const handleLogout = (url?: string) => {
    setUser(null);
    setIssuer(null);
    sessionStorage.removeItem(authConfig.token);
    sessionStorage.removeItem(authConfig.userData);
    sessionStorage.removeItem(authConfig.issuerData);
    if (url) {
      window.location.pathname = '/';
    }
  };

  const values = {
    user,
    issuer,
    roles,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    register: handleRegister,
    onboardIssuer: handleOnboardIssuer,
    loginViaTfaVerification: handleLoginViaTfaVerification,
    forgotPassword: (
      payload: ForgotPasswordPayload,
      successCallback?: SuccessCallbackType,
      errorCallback?: ErrCallbackType
    ) =>
      handleRetrievePassword(
        payload,
        authConfig.retrievePasswordOneEndpoint,
        successCallback,
        errorCallback
      ),
    resetPassword: (
      payload: ResetPasswordPayload,
      successCallback?: SuccessCallbackType,
      errorCallback?: ErrCallbackType
    ) =>
      handleRetrievePassword(
        payload,
        authConfig.retrievePasswordTwoEndpoint,
        successCallback,
        errorCallback
      ),
    updatePassword: handleUpdatePassword,
    logout: handleLogout
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
