import IconsWrapper from './IconsWrapper';

export const BusinessesIcon = () => {
  return (
    <IconsWrapper>
      <path
        d="M18.32 12.7508H15C12.51 12.7508 11.25 11.3408 11.25 8.55067V5.68058C11.25 4.66054 11.37 3.20049 12.43 2.40047C13.32 1.74044 14.6 1.69044 16.49 2.24046C18.97 2.96049 21.04 5.03055 21.76 7.51064C22.31 9.3907 22.26 10.6807 21.6 11.5608C20.8 12.6308 19.34 12.7508 18.32 12.7508ZM14.28 3.3605C13.87 3.3605 13.55 3.4405 13.34 3.60051C12.95 3.89052 12.76 4.57054 12.76 5.68058V8.56067C12.76 10.8007 13.62 11.2608 15.01 11.2608H18.33C19.43 11.2608 20.11 11.0708 20.41 10.6807C20.76 10.2207 20.73 9.3007 20.34 7.95065C19.76 5.98059 18.06 4.27053 16.09 3.70051C15.35 3.4705 14.75 3.3605 14.28 3.3605Z"
        fill="currentColor"
      />
      <path
        d="M11.0704 22.751C10.5404 22.751 10.0004 22.711 9.46037 22.621C5.37037 21.9609 2.04037 18.6408 1.38037 14.5507C0.530371 9.29052 3.92037 4.33036 9.11037 3.27032C9.52037 3.19032 9.91037 3.45033 10.0004 3.85034C10.0804 4.26035 9.82037 4.65037 9.42037 4.74037C5.03037 5.6404 2.15037 9.84054 2.88037 14.3107C3.44037 17.7708 6.25037 20.5809 9.71037 21.1409C14.2004 21.8609 18.3904 18.9708 19.2804 14.5607C19.3604 14.1507 19.7604 13.8907 20.1604 13.9707C20.5704 14.0507 20.8304 14.4507 20.7504 14.8507C19.8004 19.5209 15.7204 22.751 11.0704 22.751Z"
        fill="currentColor"
      />
    </IconsWrapper>
  );
};
