import React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip } from '@mui/material';

import IconSelect from '@/assets/Icons';
import { useAuth } from '@/hooks';

const MenuWrapper = styled(Menu)(({ theme }) => ({
  overflow: 'visible',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
    boxShadow: 'none'
  }
}));

const SuperMenuBar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (url?: string) => {
    if (url) {
      navigate(url);
    }
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout('/');
    handleClose();
  };

  return (
    <React.Fragment>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>{user?.username[0].toUpperCase()}</Avatar>
        </IconButton>
      </Tooltip>
      <MenuWrapper
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => handleClose()}
        onClick={() => handleClose()}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleClose('/settings')}>
          <ListItemIcon>
            <IconSelect name="settings" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <IconSelect name="logout" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MenuWrapper>
    </React.Fragment>
  );
};

export default SuperMenuBar;
