import { Box, keyframes, styled } from '@mui/material';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Circular = styled(Box)({
  border: '3px solid #f3f3f3',
  borderTop: '3px solid #F56329',
  borderRadius: ' 50%',
  width: '28px',
  height: '28px',
  animation: `${spin} 1s linear infinite`
});

const CircularLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Circular></Circular>
    </Box>
  );
};

export default CircularLoader;
