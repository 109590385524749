import IconsWrapper from './IconsWrapper';

export const AdministrationIcon = () => {
  return (
    <IconsWrapper>
      <path
        d="M17 21.75H7C2.59 21.75 1.25 20.41 1.25 16V8C1.25 3.59 2.59 2.25 7 2.25H17C21.41 2.25 22.75 3.59 22.75 8V16C22.75 20.41 21.41 21.75 17 21.75ZM7 3.75C3.42 3.75 2.75 4.43 2.75 8V16C2.75 19.57 3.42 20.25 7 20.25H17C20.58 20.25 21.25 19.57 21.25 16V8C21.25 4.43 20.58 3.75 17 3.75H7Z"
        fill="currentColor"
      />
      <path
        d="M19 8.75H14C13.59 8.75 13.25 8.41 13.25 8C13.25 7.59 13.59 7.25 14 7.25H19C19.41 7.25 19.75 7.59 19.75 8C19.75 8.41 19.41 8.75 19 8.75Z"
        fill="currentColor"
      />
      <path
        d="M19 12.75H15C14.59 12.75 14.25 12.41 14.25 12C14.25 11.59 14.59 11.25 15 11.25H19C19.41 11.25 19.75 11.59 19.75 12C19.75 12.41 19.41 12.75 19 12.75Z"
        fill="currentColor"
      />
      <path
        d="M19 16.75H17C16.59 16.75 16.25 16.41 16.25 16C16.25 15.59 16.59 15.25 17 15.25H19C19.41 15.25 19.75 15.59 19.75 16C19.75 16.41 19.41 16.75 19 16.75Z"
        fill="currentColor"
      />
      <path
        d="M8.49945 12.0399C7.08945 12.0399 5.93945 10.8899 5.93945 9.47992C5.93945 8.06992 7.08945 6.91992 8.49945 6.91992C9.90945 6.91992 11.0595 8.06992 11.0595 9.47992C11.0595 10.8899 9.90945 12.0399 8.49945 12.0399ZM8.49945 8.41992C7.91945 8.41992 7.43945 8.89992 7.43945 9.47992C7.43945 10.0599 7.91945 10.5399 8.49945 10.5399C9.07945 10.5399 9.55945 10.0599 9.55945 9.47992C9.55945 8.89992 9.07945 8.41992 8.49945 8.41992Z"
        fill="currentColor"
      />
      <path
        d="M11.9999 17.0798C11.6199 17.0798 11.2899 16.7898 11.2499 16.3998C11.1399 15.3198 10.2699 14.4498 9.17991 14.3498C8.71991 14.3098 8.25991 14.3098 7.79991 14.3498C6.70991 14.4498 5.83991 15.3098 5.72991 16.3998C5.68991 16.8098 5.31991 17.1198 4.90991 17.0698C4.49991 17.0298 4.19991 16.6598 4.23991 16.2498C4.41991 14.4498 5.84991 13.0198 7.65991 12.8598C8.20991 12.8098 8.76991 12.8098 9.31991 12.8598C11.1199 13.0298 12.5599 14.4598 12.7399 16.2498C12.7799 16.6598 12.4799 17.0298 12.0699 17.0698C12.0499 17.0798 12.0199 17.0798 11.9999 17.0798Z"
        fill="currentColor"
      />
    </IconsWrapper>
  );
};
