import { useState } from 'react';
import {
  FormControl,
  TextField,
  Typography,
  IconButton,
  TextFieldProps,
  styled
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { FieldError } from 'react-hook-form';
import IconSelect from '@/assets/Icons';

const TextFieldStyled = styled(TextField)<TextFieldProps>(() => ({}));

type TextInputProp = {
  error?: FieldError;
  fullwidth?: boolean;
  shrink?: boolean;
  margin?: string;
} & Omit<TextFieldProps, 'error'>;

const TextInput = ({
  label,
  type,
  error,
  id,
  fullwidth = true,
  shrink = true,
  InputProps,
  ...rest
}: TextInputProp) => {
  const [showPassword, setShowPassword] = useState(false);
  const [shrinking, setShrinking] = useState(shrink);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleFocus = () => setShrinking((prev) => true);

  return (
    <FormControl fullWidth={fullwidth}>
      <TextFieldStyled
        size="medium"
        id={id}
        label={label}
        error={!!error?.message}
        helperText={error?.message?.trim()}
        onFocus={handleFocus}
        sx={{ background: 'transparent' }}
        type={showPassword ? 'text' : type}
        InputLabelProps={{
          shrink: shrinking
        }}
        InputProps={{
          ...(type === 'password' && {
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                disableRipple
              >
                <Typography>{!showPassword ? <Visibility /> : <VisibilityOff />}</Typography>
              </IconButton>
            )
          }),
          ...(type === 'search' && {
            startAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                disableRipple
                sx={{ marginRight: '15px' }}
              >
                <Typography>
                  <IconSelect name="search" />
                </Typography>
              </IconButton>
            )
          }),
          ...InputProps
        }}
        {...rest}
      />
    </FormControl>
  );
};

export default TextInput;
