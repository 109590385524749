import React from 'react';
import IconsWrapper from './IconsWrapper';

export const BillsIcon = () => {
  return (
    <IconsWrapper>
      <svg
        width="64px"
        height="64px"
        viewBox="-1.5 0 33 33"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <title>wallet</title> <desc>Created with Sketch Beta.</desc> <defs> </defs>{' '}
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            {' '}
            <g id="Icon-Set" transform="translate(-257.000000, -774.000000)" fill="currentColor">
              {' '}
              <path
                d="M285,793 L280,793 C279.448,793 279,793.448 279,794 L279,798 C279,798.553 279.448,799 280,799 L285,799 L285,804 C285,804.553 284.552,805 284,805 L260,805 C259.448,805 259,804.553 259,804 L259,785 L284,785 C284.552,785 285,785.447 285,786 L285,793 L285,793 Z M285,796 L285,797 L281,797 L281,796 L281,795 L285,795 L285,796 L285,796 Z M283,777 L283,783 L263.5,783 L283,777 L283,777 Z M285,783 L285,776 C285,775.447 284.764,775.141 284.25,774.938 C283.854,774.781 283.469,774.875 283,775 L257,783 L257,805 C257,806.104 257.896,807 259,807 L285,807 C286.104,807 287,806.104 287,805 L287,785 C287,783.896 286.104,783 285,783 L285,783 Z"
                id="wallet"
              >
                {' '}
              </path>{' '}
            </g>{' '}
          </g>{' '}
        </g>
      </svg>
    </IconsWrapper>
  );
};
