import axios, { AxiosInstance } from 'axios';
import { APP_BASE_URL } from '@/config/baseUrl';
import { getFromSStorage } from '@/libs/storage';
import authConfig from '@/config/auth';
import toast from 'react-hot-toast';

export const formatRequestPayload = <T>(payload: T): { data: T } => {
  return { data: { ...payload } };
};

const invalidateSession = (message: string) => {
  if (message === 'Session is now invalid. Please login again') {
    toast.error(message, {});
    sessionStorage.removeItem(authConfig.token);
    setTimeout(() => window.location.reload(), 1000);
  }
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: APP_BASE_URL
});

axiosInstance.interceptors.request.use((config) => {
  const token = config?.data?.data?.__token || getFromSStorage(authConfig.token);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    // handle response
    if (response?.data?.error?.status > 0) {
      invalidateSession(response.data.error.message);

      return Promise.reject(response);
    }
    return response;
  },
  function (error) {
    // handle error
    if (error?.response?.status === 401) {
      invalidateSession(error.response.data.error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
