import React, { createContext, useState } from 'react';
import { ThemeProvider, createTheme, colors, PaletteMode, GlobalStyles } from '@mui/material';
import GlobalStyling from './globalStyles';

type ThemeContextType = {
  mode: PaletteMode;
  handleSetTheme: () => void;
};

export const ThemeContext = createContext({} as ThemeContextType);

type ThemeContextProviderType = {
  children: React.ReactNode;
};

const ThemeContextProvider = ({ children }: ThemeContextProviderType) => {
  const [mode, setMode] = useState<PaletteMode>('light');
  const handleSetTheme = () => {
    toggleColorMode();
  };

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const getDesignTokens = (mode: PaletteMode) => ({
    typography: {
      fontFamily: ['lato', 'sans-serif'].join(',')
    },
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // 👇 palette values for light mode

            primary: {
              main: '#272727',
              card: colors.grey['100'],
              icon: '#1C274C',
              appBar: '#fff'
            },
            text: {
              primary: colors.grey[900]
            }
          }
        : {
            // 👇 palette values for dark mode

            primary: {
              main: '#fff',
              card: '#272727',
              icon: '#fff',
              appBar: '#272727'
            },
            text: {
              primary: colors.common.white
            }
          })
    },
    status: {
      danger: '#e53e3e'
    }
  });

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const values = { handleSetTheme, mode };

  return (
    <ThemeContext.Provider value={values}>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={() => GlobalStyling(theme) as any} />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
