import IconsWrapper from './IconsWrapper';
const UpdateIcon = () => {
  return (
    <IconsWrapper>
      <svg
        fill="currentColor"
        width="64px"
        height="64px"
        viewBox="0 0 24 24"
        id="create-note-alt"
        data-name="Flat Line"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            id="secondary"
            d="M19.44,8.22C17.53,10.41,14,10,14,10s-.39-4,1.53-6.18a3.49,3.49,0,0,1,.56-.53L18,4l.47-1.82A8.19,8.19,0,0,1,21,2S21.36,6,19.44,8.22Z"
            style={{
              fill: 'none',
              strokeWidth: 1
            }}
          ></path>
          <path
            id="primary"
            d="M19.44,8.22C17.53,10.41,14,10,14,10s-.39-4,1.53-6.18a3.49,3.49,0,0,1,.56-.53L18,4l.47-1.82A8.19,8.19,0,0,1,21,2S21.36,6,19.44,8.22ZM14,10l-2,2"
            style={{
              fill: 'none',
              strokeWidth: 1,
              stroke: 'currentColor',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          ></path>
          <path
            id="primary-2"
            data-name="primary"
            d="M12,3H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V12"
            style={{
              fill: 'none',
              strokeWidth: 1,
              stroke: 'currentColor',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
          ></path>
        </g>
      </svg>
    </IconsWrapper>
  );
};

export default UpdateIcon;
