import IconsWrapper from './IconsWrapper';

export const ActivityIcon = () => {
  return (
    <IconsWrapper>
      <path
        d="M21 20.25H11C10.59 20.25 10.25 19.91 10.25 19.5C10.25 19.09 10.59 18.75 11 18.75H21C21.41 18.75 21.75 19.09 21.75 19.5C21.75 19.91 21.41 20.25 21 20.25Z"
        fill="currentColor"
      />
      <path
        d="M21 13.25H11C10.59 13.25 10.25 12.91 10.25 12.5C10.25 12.09 10.59 11.75 11 11.75H21C21.41 11.75 21.75 12.09 21.75 12.5C21.75 12.91 21.41 13.25 21 13.25Z"
        fill="currentColor"
      />
      <path
        d="M21 6.25H11C10.59 6.25 10.25 5.91 10.25 5.5C10.25 5.09 10.59 4.75 11 4.75H21C21.41 4.75 21.75 5.09 21.75 5.5C21.75 5.91 21.41 6.25 21 6.25Z"
        fill="currentColor"
      />
      <path
        d="M3.99945 7.24945C3.80945 7.24945 3.61945 7.17945 3.46945 7.02945L2.46945 6.02945C2.17945 5.73945 2.17945 5.25945 2.46945 4.96945C2.75945 4.67945 3.23945 4.67945 3.52945 4.96945L3.99945 5.43945L6.46945 2.96945C6.75945 2.67945 7.23945 2.67945 7.52945 2.96945C7.81945 3.25945 7.81945 3.73945 7.52945 4.02945L4.52945 7.02945C4.37945 7.17945 4.18945 7.24945 3.99945 7.24945Z"
        fill="currentColor"
      />
      <path
        d="M3.99945 14.2495C3.80945 14.2495 3.61945 14.1795 3.46945 14.0295L2.46945 13.0295C2.17945 12.7395 2.17945 12.2595 2.46945 11.9695C2.75945 11.6795 3.23945 11.6795 3.52945 11.9695L3.99945 12.4395L6.46945 9.96945C6.75945 9.67945 7.23945 9.67945 7.52945 9.96945C7.81945 10.2595 7.81945 10.7395 7.52945 11.0295L4.52945 14.0295C4.37945 14.1795 4.18945 14.2495 3.99945 14.2495Z"
        fill="currentColor"
      />
      <path
        d="M3.99945 21.2495C3.80945 21.2495 3.61945 21.1795 3.46945 21.0295L2.46945 20.0295C2.17945 19.7395 2.17945 19.2595 2.46945 18.9695C2.75945 18.6795 3.23945 18.6795 3.52945 18.9695L3.99945 19.4395L6.46945 16.9695C6.75945 16.6795 7.23945 16.6795 7.52945 16.9695C7.81945 17.2595 7.81945 17.7395 7.52945 18.0295L4.52945 21.0295C4.37945 21.1795 4.18945 21.2495 3.99945 21.2495Z"
        fill="currentColor"
      />
    </IconsWrapper>
  );
};
