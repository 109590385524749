import { Suspense, lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { AuthGuard, GuestGuard } from '@/guards';
import FallbackSpinner from '@/components/FallbackSpinner';
import DashboardLayout from '@/layout/DashboardLayout';

function Loadable<T extends JSX.IntrinsicAttributes>(Component: React.FC<T>) {
  return (props: T) => {
    return (
      <Suspense fallback={<FallbackSpinner />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

const Login = Loadable(lazy(() => import('../pages/Login')));
const Onboarding = Loadable(lazy(() => import('../pages/Onboarding')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Withdrawal = Loadable(lazy(() => import('../pages/Withdrawal')));
const MerchantKyc = Loadable(lazy(() => import('../pages/MerchantKYC')));
const Bills = Loadable(lazy(() => import('../pages/Bills')));
// const BillCategories = Loadable(lazy(() => import('../pages/BillCategories')));

const ActivityLog = Loadable(lazy(() => import('../pages/ActivityLog')));
const Transactions = Loadable(lazy(() => import('../pages/Transactions')));
const Discounts = Loadable(lazy(() => import('../pages/Discounts')));
const DiscountLogs = Loadable(lazy(() => import('../pages/Discounts/components/DiscountLogs')));

const Pos = Loadable(lazy(() => import('../pages/Pos')));
const SubAgents = Loadable(lazy(() => import('../pages/SubAgents')));
const Cashiers = Loadable(lazy(() => import('../pages/Cashiers')));
const CreateSubAgents = Loadable(
  lazy(() => import('../pages/SubAgents/components/SubAgentCreate'))
);
const Deductions = Loadable(lazy(() => import('../pages/Deductions')));
const CreateAccountSettings = Loadable(
  lazy(() => import('../pages/SubAgents/components/AccountSettings'))
);
const ProductCategory = Loadable(lazy(() => import('../pages/ProductCategory')));
const Devices = Loadable(lazy(() => import('../pages/Devices')));
const Settings = Loadable(lazy(() => import('../pages/Settings')));
const AdminStaffSettings = Loadable(lazy(() => import('../pages/Settings/AdminStaff')));
const GiftCard = Loadable(lazy(() => import('../pages/GiftCard')));
const ApiKeys = Loadable(lazy(() => import('../pages/ApiKeys')));
const AccountSettings = Loadable(lazy(() => import('../pages/AccountSettings')));
const AccessControl = Loadable(lazy(() => import('../pages/AccessControl')));
const InterstateTrips = Loadable(lazy(() => import('../pages/InterState/Trips')));
const InterstateTripDetails = Loadable(lazy(() => import('../pages/InterState/Trip')));
const InterstatePendingTickets = Loadable(lazy(() => import('../pages/InterState/PendingTickets')));
const InterstateTripRoutes = Loadable(lazy(() => import('../pages/InterState/Trips/Route')));
const InterstateTripTerminals = Loadable(lazy(() => import('../pages/InterState/Trips/Terminal')));
const InterstateManifest = Loadable(lazy(() => import('../pages/InterState/Manifests')));
const Invoice = Loadable(lazy(() => import('../pages/Invoice')));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/app" replace />
      },
      { path: 'app', element: <Dashboard /> },
      {
        path: 'bills',
        children: [
          { index: true, element: <Bills /> },
          { path: '/bills/categories', element: <ProductCategory /> }
        ]
      },
      { path: '/merchants-kyc', element: <MerchantKyc /> },
      { path: '/activity-log', element: <ActivityLog /> },
      {
        path: '/sub-agents',
        children: [
          { index: true, element: <SubAgents /> },
          {
            path: 'create',
            element: <CreateSubAgents />
          },
          {
            path: 'update',
            element: <CreateSubAgents />
          },
          {
            path: 'update-kyc',
            element: <CreateSubAgents />
          },
          {
            path: 'account-settings',
            element: <CreateAccountSettings />
          }
        ]
      },
      { path: '/cashiers', element: <Cashiers /> },
      { path: '/transactions', element: <Transactions /> },
      {
        path: '/discounts',
        children: [
          { index: true, element: <Discounts /> },
          {
            path: 'logs',
            element: <DiscountLogs />
          }
        ]
      },
      { path: '/withdrawal', element: <Withdrawal /> },
      { path: '/pos-receipt', element: <Pos /> },
      { path: '/deductions', element: <Deductions /> },
      { path: '/product-category', element: <ProductCategory /> },
      {
        path: '/interstate',
        children: [
          {
            index: true,
            element: <div />
          },
          {
            path: 'trips',
            element: <InterstateTrips />
          },
          { path: 'trips/:tripId', element: <InterstateTripDetails /> },
          { path: 'pending-tickets', element: <InterstatePendingTickets /> },
          {
            path: 'routes',
            element: <InterstateTripRoutes />
          },
          {
            path: 'terminals',
            element: <InterstateTripTerminals />
          },
          {
            path: 'manifests',
            element: <InterstateManifest />
          }
        ]
      },
      { path: '/devices', element: <Devices /> },
      { path: '/giftcard', element: <GiftCard /> },
      { path: '/api-keys', element: <ApiKeys /> },
      { path: '/access-control', element: <AccessControl /> },
      { path: '/account-settings', element: <AccountSettings /> },
      {
        path: '/settings',
        children: [
          {
            index: true,
            element: <Settings />
          },
          {
            path: '/settings/staff',
            element: <AdminStaffSettings />
          }
        ]
      },
      { path: '/invoice', element: <Invoice /> }
    ]
  },
  {
    path: '/',
    element: <GuestGuard />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/onboarding',
        element: <Onboarding />
      },
      {
        path: '/forgot-password',
        element: <p>Forgot Passwordd</p>
      }
    ]
  }
]);
