import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@/hooks';

export function GuestGuard() {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/app" />;
  }

  return <Outlet />;
}
