import { ResponsePayloadData } from '../types';

export type SetupTFARequestPayload = {
  email: string;
  phone: string;
};
export type SetupTFAResponsePayload = {
  authCode: string;
};

export type CompleteTFARequestPayload = {
  type: TFA_MEDIUM;
  otp: string;
};
export type CompleteTFAResponsePayload = null;

export type VerifyTFARequestPayload = {
  otp: string;
  type: TFA_MEDIUM;
  key: string;
};
export type VerifyTFAResponsePayload = ResponsePayloadData<any[]>;

export type RequestTFARequestPayload = {
  type: TFA_MEDIUM;
  key?: string;
  token?: string; // this is used when you are request for OTP when the user isn't authenticated
};
export type RequestTFAResponsePayload = ResponsePayloadData<any[]>;

export enum TFA_MEDIUM {
  'Email' = 0,
  'AUTHENTICATOR_APP' = 1,
  'SMS' = 2
}
