import IconsWrapper from './IconsWrapper';

export const GiftCardIcon = () => {
  return (
    <IconsWrapper>
      <svg
        fill="currentColor"
        width="64px"
        height="64px"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M2.5 11.25h2v1.25h-2z"></path>
          <path d="M14.75 4H13.3a2.1 2.1 0 0 0-1.87-3 4.24 4.24 0 0 0-2.84 1 3.42 3.42 0 0 0-.59.67A3.42 3.42 0 0 0 7.41 2a4.24 4.24 0 0 0-2.84-1A2.1 2.1 0 0 0 2.7 4H1.25A1.25 1.25 0 0 0 0 5.25v8.5A1.25 1.25 0 0 0 1.25 15h13.5A1.25 1.25 0 0 0 16 13.75v-8.5A1.25 1.25 0 0 0 14.75 4zM9.42 2.94a3 3 0 0 1 2-.69.85.85 0 0 1 0 1.7H8.74a2.32 2.32 0 0 1 .68-1.01zm-4.85-.69a3 3 0 0 1 2 .69 2.32 2.32 0 0 1 .68 1H4.57a.85.85 0 0 1 0-1.7zm-3.32 3h13.5V6.5H1.25V5.25zm0 8.5V9h13.5v4.75z"></path>
        </g>
      </svg>
    </IconsWrapper>
  );
};
