import {
  Collapse,
  Divider,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Tooltip,
  styled
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NavLink as RctNavLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import React, { useState, useRef, useEffect } from 'react';

import IconSelect from '@/assets/Icons';
import type { NavItem as TNavItem } from '@/layout/types';

const NavLink = styled(RctNavLink)(({ theme }) => ({
  display: 'flex',
  color: 'inherit',
  textDecoration: 'none',
  minHeight: 48,
  position: 'relative'
}));

const ListItem = styled(MuiListItem)(() => ({}));
const List = styled(MuiList)(() => ({}));
const ListItemText = styled(MuiListItemText)(() => ({}));
const ListItemIcon = styled(MuiListItemIcon)(() => ({}));

const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ isActive }) => ({
  fontWeight: isActive ? 'bold' : '',
  background: isActive ? '#F56329 !important' : '',
  position: 'relative'
}));

const SubListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ isActive }) => ({
  fontWeight: isActive ? 'bold' : '',
  color: isActive ? '#F56329 !important' : '',
  position: 'relative'
}));

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    transform: 'translatex(50px) !important'
  }
}));

type NavItemProps = {
  item: TNavItem;
  isExpanded: boolean;
  last?: boolean;
  onClickLink?: () => void;
};

const NavItem = ({ item, isExpanded, last, onClickLink }: NavItemProps) => {
  const { title, path, icon, children } = item;

  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const anchorRef = useRef<HTMLLIElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const label = pathname.includes('-') ? pathname.split('-').join(' ').slice(1) : pathname.slice(1);
  const hasChildren = Boolean(children?.length);
  const hasActiveChild = Boolean(children?.find((child) => child.path === pathname));

  useEffect(() => {
    if (hasChildren) {
      setOpen(hasActiveChild);
    }
  }, [hasActiveChild, hasChildren]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!hasChildren) {
      onClickLink?.();
      return;
    }
    e.preventDefault();
    if (isExpanded) {
      setOpen((prev) => !prev);
    } else {
      setAnchorEl(anchorRef.current);
    }
  };

  return isExpanded ? (
    <>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <Helmet>
          <title>{label}</title>
        </Helmet>

        <NavLink to={path} onClick={handleClick}>
          {({ isActive }) => {
            return (
              <ListItemButton isActive={isActive || hasActiveChild}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isExpanded ? 3 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  <IconSelect name={icon} />
                </ListItemIcon>
                <ListItemText
                  primary={`${title}`}
                  sx={{
                    opacity: isExpanded ? 1 : 0,
                    textTransform: 'capitalize',
                    '& .MuiTypography-root': {
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }
                  }}
                />
                {(() => {
                  if (!hasChildren) return null;
                  return open ? <ExpandLess /> : <ExpandMore />;
                })()}
              </ListItemButton>
            );
          }}
        </NavLink>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {children?.map((menu, i) => {
            return (
              <NavLink key={i} to={menu.path} onClick={onClickLink}>
                {({ isActive }) => {
                  return (
                    <SubListItemButton isActive={isActive}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isExpanded ? 3 : 'auto',
                          justifyContent: 'center'
                        }}
                      >
                        <IconSelect name={menu.icon} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${menu.title}`}
                        sx={{
                          opacity: isExpanded ? 1 : 0,
                          textTransform: 'capitalize'
                        }}
                      />
                    </SubListItemButton>
                  );
                }}
              </NavLink>
            );
          })}
        </List>
      </Collapse>
      {!last && <Divider />}
    </>
  ) : (
    <ListItem ref={anchorRef} disablePadding sx={{ display: 'block' }}>
      <Tooltip title={title} placement="right">
        <NavLink to={path} onClick={handleClick}>
          {({ isActive }) => {
            return (
              <ListItemButton isActive={isActive || hasActiveChild}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center'
                  }}
                >
                  <IconSelect name={icon} />
                </ListItemIcon>
              </ListItemButton>
            );
          }}
        </NavLink>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => handleCloseMenu()}
        onClick={() => handleCloseMenu()}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {children?.map((menu, index) => {
          return (
            <MuiMenuItem key={index} sx={{ padding: 0 }}>
              <div>
                <NavLink to={menu.path} onClick={onClickLink}>
                  {({ isActive }) => {
                    return (
                      <SubListItemButton sx={{ py: 0 }} isActive={isActive}>
                        <ListItemText
                          primary={`${menu.title}`}
                          sx={{
                            textTransform: 'capitalize'
                          }}
                        />
                      </SubListItemButton>
                    );
                  }}
                </NavLink>
              </div>
            </MuiMenuItem>
          );
        })}
      </Menu>
      {!last && <Divider />}
    </ListItem>
  );
};

export default NavItem;
