import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth'
})<AppBarProps>(({ theme, open, drawerWidth }) => {
  return {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.primary.card,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      display: 'none',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        display: 'block'
      }
    }),
    ...(!open && {
      marginLeft: drawerWidth,
      width: '100%',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - 65px)`
      }
    })
  };
});

type AppBarUiProp = {
  drawerWidth: number;
  children: ReactNode;
  open: boolean;
};
const AppBarUi = ({ drawerWidth, children, open }: AppBarUiProp) => {
  return (
    <AppBar
      position="fixed"
      drawerWidth={drawerWidth}
      open={open}
      sx={{
        paddingY: '6px',
        boxShadow: 'none'
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};

export default AppBarUi;
