import { styled, Theme, CSSObject } from '@mui/material/styles';
import List from '@mui/material/List';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';

import { useAuth } from '@/hooks';
import { UserType } from '@/api/auth/types';

import sidebarConfig from '../SidebarConfig';
import sidebarCommonConfig from '../SidebarCommonConfig';
import NavItem from './NavItem';

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  display: 'flex',
  background: theme.palette.primary.card,
  justifyContent: 'space-between',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  [theme.breakpoints.up('md')]: {
    width: drawerWidth
  }
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  background: theme.palette.primary.card,
  display: 'none',
  justifyContent: 'space-between',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    display: 'flex'
  }
});

interface CustomDrawerProps extends DrawerProps {
  drawerWidth: number;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'drawerWidth'
})<CustomDrawerProps>(({ theme, open, drawerWidth }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  background: 'purple',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerWidth)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  display: 'flex',
  alignItems: 'center',
  ...theme.mixins.toolbar
}));

type SidebarProp = {
  drawerWidth: number;
  open: boolean;
  isScreenSizeMediumAndAbove: boolean;
  handleDrawerToggle: () => void;
};

const Sidebar = ({
  drawerWidth,
  open,
  handleDrawerToggle,
  isScreenSizeMediumAndAbove
}: SidebarProp) => {
  const { user, roles } = useAuth();

  const onClickLink = () => {
    if (isScreenSizeMediumAndAbove) return;
    handleDrawerToggle();
  };

  const privilegedSidebarConfig = sidebarConfig.filter((config) => {
    if (config.isMerchant === true && !user?.accountIsMerchant) {
      return false;
    }

    if (config.isMerchant === false && user?.accountIsMerchant) {
      return false;
    }

    if (!(config.roleTag && config.userType)) {
      return config;
    }

    if (user?.usertype === UserType.ISSUER) {
      return config.userType.includes(user.usertype);
    }

    return config.userType.includes(user?.usertype as UserType) && roles?.includes(config.roleTag);
  });

  return (
    <Drawer
      variant={isScreenSizeMediumAndAbove ? 'permanent' : 'temporary'}
      open={open}
      drawerWidth={drawerWidth}
      onClose={handleDrawerToggle}
    >
      <Box sx={{ overflowY: 'scroll' }} component={'section'}>
        <DrawerHeader>
          {open ? (
            <img
              src="https://frontendgroupdiag.blob.core.windows.net/websiteresource/tapsite/logo.png"
              alt=""
              width={80}
              height={80}
            />
          ) : (
            <img
              src="https://frontendgroupdiag.blob.core.windows.net/websiteresource/tapsite/logo.png"
              alt=""
              width={50}
              height={50}
            />
          )}
        </DrawerHeader>

        <List>
          {privilegedSidebarConfig.map((item, index) => {
            return (
              <NavItem
                key={item.path + index}
                item={item}
                isExpanded={open}
                last={sidebarConfig.length === index + 1}
                onClickLink={onClickLink}
              />
            );
          })}
        </List>
      </Box>
      <Box>
        <Divider />
        <List>
          {sidebarCommonConfig.map((item, index) => (
            <NavItem
              key={item.path + index}
              item={item}
              isExpanded={open}
              last={sidebarCommonConfig.length === index + 1}
              onClickLink={onClickLink}
            />
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
