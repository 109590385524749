export enum UserType {
  ISSUER = 0,
  ISSUER_LIMITED = 10,
  ADMIN = 1,
  ADMIN_LIMITED = 11,
  ISSUER_GUEST = -1,
  // inactive
  HQ_API = 200,
  CASHIER = 2,
  BUS = 3,
  CASHIER_NODEVICE = 21,
  ANY = 4,
  AGENCY = 22,
  API_USR_SM = 100,
  API_USR_M = 111
}

export interface User {
  email: string;
  username: string;
  phone: string;
  fullname: string;
  address: string;
  usertype: UserType;
  gender: string;
  accountIsMerchant: boolean;
  issuerID: string;
  merchantID: string;
  dateCreated: number;
  name?: string;
  is2FAEnabled: boolean;
  valid: UserAccountValidityStatus;
}

export interface Issuer {
  name: string;
  issuerName: string;
  balance: number;
  email: string;
  id: string;
  phone: string;
  issuerID: string;
  status: number;
  k: any;
  kstr: any;
  creator: string;
  country: string;
  currency: string;
  poolaccount: string;
  poolAccount: string;
  issuerAccount: string;
  settlementAccount: string;
  token: string;
  commission: number;
  reqKey: any;
  issuertype: any;
  totalPoint: number;
  pointLeft: number;
  isstaging: number;
  itype: string;
  manualCharge: number;
  chargeIsFlat: number;
  chargeValue: number;
  merchantNumber: string;
}

export type LoginPayload = {
  username: string; //'superagent01';
  password: string; //'tapadmin12';
  remember?: boolean;
  isFromMobileApp?: false;
};

export type LoginResponsePayload = {
  data: User[];
  token: string;
  roles: string[];
  issuerProfile: Issuer[];
  merchantID: string;
  usertype: number;
  access: number;
  isTFARequired: boolean;
};

export type RegisterPayload = Omit<User, 'issuerID' | 'merchantID' | 'dateCreated'> & {
  phone: string;
  state: string;
  lg: string;
  password: string;
};

export type ForgotPasswordPayload = Pick<User, 'username' | 'email'>;

export type ResetPasswordPayload = Pick<User, 'username' | 'email'> & {
  password: string;
};

export type UpdatePasswordPayload = {
  oldPassword: string;
  newPassword: string;
};

export type OnboardIssuerRequestPayload = {
  email: string;
  password: string;
  fullname: string;
  phone: string;
  username: string;
  address: string;
  gender: string;
  usertype: string;
  state: string;
  lg: string;
};

export enum UserAccountValidityStatus {
  Valid = 1,
  Invalid = 0
}
