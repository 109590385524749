import axios, { formatRequestPayload } from '..';
import { useMutation } from '@tanstack/react-query';
import { CustomUseMutationOptions } from '../types';
import * as securityTfa from './types';

export const useSetupTFaMutation = (
  options?: CustomUseMutationOptions<
    securityTfa.SetupTFARequestPayload,
    securityTfa.SetupTFAResponsePayload
  >
) => {
  return useMutation({
    ...options,
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
    },
    mutationFn: (data) =>
      axios.post('/v1/admin/setup-tfa', formatRequestPayload(data)).then((res) => res.data)
  });
};

export const useVerifyTFaMutation = (
  options?: CustomUseMutationOptions<
    securityTfa.VerifyTFARequestPayload,
    securityTfa.VerifyTFAResponsePayload
  >
) => {
  return useMutation({
    ...options,
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
    },
    mutationFn: (data) =>
      axios.post('/v1/admin/verify-tfa', formatRequestPayload(data)).then((res) => res.data)
  });
};

export const useCompleteTFaMutation = (
  options?: CustomUseMutationOptions<
    securityTfa.CompleteTFARequestPayload,
    securityTfa.CompleteTFAResponsePayload
  >
) => {
  return useMutation({
    ...options,
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
    },
    mutationFn: (data) =>
      axios.post('/v1/admin/complete-tfa', formatRequestPayload(data)).then((res) => res.data)
  });
};

export const useRequestTFaMutation = (
  options?: CustomUseMutationOptions<
    securityTfa.RequestTFARequestPayload,
    securityTfa.RequestTFARequestPayload
  >
) => {
  return useMutation({
    ...options,
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
    },
    mutationFn: (data) =>
      axios.post('/v1/admin/request-tfa', formatRequestPayload(data)).then((res) => res.data)
  });
};
