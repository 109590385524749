import { Box } from '@mui/material';
import IconsWrapper from './IconsWrapper';

export const EmptyIcon = () => {
  return (
    <IconsWrapper>
      <Box width={'64px'} height={'64px'}></Box>
    </IconsWrapper>
  );
};
