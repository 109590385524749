import IconsWrapper from './IconsWrapper';

export const AccessIcon = () => {
  return (
    <IconsWrapper>
      <svg
        width="64px"
        height="64px"
        viewBox="0 0 48 48"
        id="b"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="1"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <defs>
            <style></style>
          </defs>
          <polyline
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
            points="24 18.0105 31.0407 25.0513 24 32.092 16.9593 25.0513 24 18.0105"
          ></polyline>
          <polyline
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
            points="21.8975 9.4521 37.4966 25.0513 24 38.5479 10.5034 25.0513 17.5367 18.0179"
          ></polyline>
          <polyline
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}
            points="21.8975 3 43.9487 25.0513 24 45 4.0513 25.0513 11.0847 18.0179"
          ></polyline>
        </g>
      </svg>
    </IconsWrapper>
  );
};
