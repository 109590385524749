import SvgIcon from '@mui/material/SvgIcon';
import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';

const SvgWrapper = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.primary.icon
}));

type IconsWrapperProp = {
  children: ReactNode;
};
const IconsWrapper = ({ children }: IconsWrapperProp) => {
  return <SvgWrapper sx={{ width: '20px', height: '20px' }}>{children}</SvgWrapper>;
};

export default IconsWrapper;
