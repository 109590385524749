import React from 'react';
import IconsWrapper from './IconsWrapper';

export const DevicesIcon = () => {
  return (
    <IconsWrapper>
      <svg
        fill="currentColor"
        width="64px"
        height="64px"
        viewBox="0 0 36 36"
        version="1.1"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <title>devices-line</title>{' '}
          <path d="M32,13H24a2,2,0,0,0-2,2V30a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V15A2,2,0,0,0,32,13Zm0,2V26H24V15ZM24,30V27.6h8V30Z"></path>
          <path d="M20,22H4V6H28v5h2V6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6V22a2,2,0,0,0,2,2H20Z"></path>
          <path d="M20,26H9a1,1,0,0,0,0,2H20Z"></path>{' '}
          <rect x="0" y="0" width="36" height="36" fillOpacity="0"></rect>{' '}
        </g>
      </svg>
    </IconsWrapper>
  );
};
